import { useState, useEffect, useCallback, useRef, createRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PanelBarSelectEventArguments } from '@progress/kendo-react-layout';

import { ICON_DESCRIPTION_ID } from 'utils/enum';
import { useAppDispatch, useAppSelector } from 'hooks/stateHooks';
import {
	getELeafletFileName,
	getEncProductId,
	getIVedaPropertiesStatus,
	setELeafletFileName,
	setEncProductId,
	setExpanded,
	setIVedaPropertiesStatus,
	setManufacturerGs1Prefix,
} from 'slices/productmoduleSlice';
import { getProductAreaIconsData } from 'slices/infoIconsDataSlice';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

import getAllProductVersionDetailsService from './services/getAllProductVersionDetailsById';
import getAllProductAttributesByGroupIdService from '../addProducts/services/getAllProductAttributesByGroupIdService';
import useDownloadProductVersionEleafletFile from './hooks/useDownloadProductVersionEleafLet';

const GeneralFunctions = () => {
	const location = useLocation();
	const { t } = useTranslation('productModule');
	const iVedaPropertiesStatus = useAppSelector(getIVedaPropertiesStatus);
	const dispatch = useAppDispatch();
	const encProductId = useAppSelector(getEncProductId);
	const eLeafletFileName = useAppSelector(getELeafletFileName);
	const eLeafletRef = createRef<HTMLInputElement>();

	const renderCount = useRef<number>(0);

	const [productName, setProductName] = useState<string>('');
	const [manufacturerName, setManufacturerName] = useState<string>('');
	const [productGroupName, setProductGroupName] = useState<string>('');
	const [editCommonAttributes, setEditCommonAttributes] = useState<boolean>(false);
	const [editGeneralAttributes, setEditGenaralAttributes] = useState<boolean>(false);
	const [editIVedaAttributes, setEditIVedaAttributes] = useState<boolean>(false);
	const [commonProductCode, setCommonProductCode] = useState<string>('');
	const [productDescription, setProductDescription] = useState<string>('');
	const [isEndUserScanEnabled, setIsEndUserScanEnabled] = useState<boolean>(true);
	const [encManufacturerID, setEncManufacturerID] = useState<any>('');
	const [productGroup, setProductGroup] = useState<any>('');

	const [generalDetailsLoading, setGeneralDetailsLoading] = useState<boolean>(false);

	const [errorState, setErrorState] = useState<Record<string, string>>({});

	const [generalFieldsData, setGeneralFieldsData] = useState<any>([]);
	const [sortedGeneralFields, setSortedGeneralFields] = useState<any>([]);

	const [panelBarStatus, setPanelBarStatus] = useState<boolean>(true);

	const [mode, setMode] = useState<any>('add');
	const [genericName, setGenericName] = useState<any>('');

	const [composition, setComposition] = useState<any>('');

	const [strength, setStrength] = useState<any>('');

	const [gs1CompanyPrefix, setGs1CompanyPrefix] = useState<any>('');

	const [storageCondition, setStorageCondition] = useState<any>('');

	const ProductAreaIconsData = useAppSelector(getProductAreaIconsData);
	const [commonAttributesInfo, setCommonAttributesInfo] = useState<string>('');
	const [generalInfo, setGeneralInfo] = useState<string>('');
	const [iVedaPropertitesInfo, setIVedaPropertitesInfo] = useState<string>('');
	const [productNameSuperScript, setProductNameSuperScript] = useState<string | null>(null);
	const [internalMaterialNumber, setInternalMaterialNumber] = useState<string>('');
	// eLeaflet pdf states

	const [downloadStatus, setDownloadStatus] = useState<boolean>(false);

	const [imageFileBase64, setImageFileBase64] = useState<string>('');
	const [imageName, setImageName] = useState<string>('');
	const [sortedIVedaPropertiesFields, setSortedIVedaPropertiesFields] = useState<any>([]);

	const [ivedaErrorState, setIvedaErrorState] = useState<Record<string, string>>({});
	const [groupChangeLoader, setGroupChangeLoader] = useState<boolean>(false);

	const [weblinkType, setWeblinkType] = useState<string>('');
	const [encVersionId, setEncVersionId] = useState<string>('');
	const [manufacturerLocation, setManufacturerLocation] = useState<any>('');

	// state for setting enable/disable serial number in product history view
	const [isSerialNumberEnabled, setIsSerialNumberEnabled] = useState<boolean>(true);

	const [iVedaData, setIVedaData] = useState({
		hsCode: '',

		remark: '',
		manufacturingSiteCode: '',

		primaryLevelGtin: '',
		secondaryLevelGtin1: '',
		secondaryLevelGtin2: '',
		secondaryLevelGtin3: '',
		tertiaryLevelGtin: '',
		manufacturerCode: '',
		encDosageIVeda: '',
		compositionIVeda: '',
		encScheduleId: '',
		encStorageConditionId: '',
		encproductType: '',
		genericNameIVeda: '',
		strengthIveda: '',
		productCodeIVeda: '',
		productNameIVeda: '',
		productType: '',
		schedule: '',
		dosageName: '',
		storageCondition: '',
	});

	const [generalField, setGeneralField] = useState<any>({
		productSecName: '',
		unitPrice: '',

		dosage: '',

		formType: '',
		packType: '',

		revisionNumber: '',
		licenseNumber: '',
		weight: '',
		ai710GermanyIFA: '',
		ai711FranceCIP: '',
		ai712SpainNationalCode: '',
		ai240AdditionalItemInfo: '',
		atPZN: '',
		beABPCode: '',
		brAnvisaRegistrationNumber: '',
		caDIN: '',
		chSwissmedic: '',
		cnSubtypeCode: '',
		dePPN: '',
		dePZN: '',
		ean13: '',
		grEOFCode: '',
		hrCroatiaNationalCode: '',
		inProductCode: '',
		internalMaterialNumber: '',
		itBollino: '',
		krKFDACode: '',
		nlKNMP: '',
		nrdNordicVNRDrugCode: '',
		ptAIMNumber: '',
		saSaudiDrugCode: '',
		usNDC442: '',
		usNDC532: '',
		usNDC541: '',
		usNDC542: '',
		ai714PortugalAIM: '',
		ai27ProductNotforSale: false,
		tNVED4: '',
		vat: '',
		siNTINCode: '',
		netContentDescription: '',
		fdaNDC11: '',
		article57Code: '',

		treacebilityCodeName: '',
		barcode: '',
		digitalCode: '',
		scanCodePrompt: false,
		callEnquiries: '',

		ukNHRN: '',
		cnProductCode: '',
		ai713BrazilAnvisa: '',
		cnPackagespec: '',
		grossWeight: '',
		netWeight: '',
		unitProductDimension: '',
		usNDCPharma: '',
		shelfLifeInDays: '',
		batchSize: '',
		fgCode: '',
		marketRegion: '',
		brandName: '',
		marketedBy: '',
	});

	// sets icon description data using icon ID

	useEffect(() => {
		// eslint-disable-next-line no-unused-expressions
		ProductAreaIconsData?.length > 0 &&
			// eslint-disable-next-line array-callback-return
			ProductAreaIconsData?.map((item: any) => {
				if (item.encIconId === ICON_DESCRIPTION_ID.COMMON_ATTRIBUTES) {
					setCommonAttributesInfo(item?.iconDescription);
				}
				if (item.encIconId === ICON_DESCRIPTION_ID.GENERAL) {
					setGeneralInfo(item?.iconDescription);
				}
				if (item.encIconId === ICON_DESCRIPTION_ID.I_VEDA_PROPERTIES) {
					setIVedaPropertitesInfo(item?.iconDescription);
				}
			});
	}, [ProductAreaIconsData]);

	/**
	 * getting all product attributes by ID
	 * @param params id
	 */
	const getAllProductAttributesByIdData = async (params: any) => {
		const ProductAttributesByIdData = await getAllProductAttributesByGroupIdService(params);
		if (ProductAttributesByIdData) {
			setGeneralFieldsData(ProductAttributesByIdData?.output);
			setGroupChangeLoader(false);
			/**
			 * error state clearing on product group change
			 */
			setIvedaErrorState({});

			setErrorState({});
		} else {
			setGroupChangeLoader(false);
			dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
		}
	};

	/** API call to get product details */
	const getProductGeneralDetailsById = useCallback(async (param: any) => {
		setGeneralDetailsLoading(true);
		const productGeneralDetailsResData = await getAllProductVersionDetailsService(param);
		if (productGeneralDetailsResData) {
			setGeneralDetailsLoading(false);
			setPanelBarStatus(false);
			setProductGroup(productGeneralDetailsResData.output.commonAttribute.encProductGroupId);
			setGeneralField(productGeneralDetailsResData.output.generalProperties);
			setIVedaData(productGeneralDetailsResData.output.ivedaProperties);

			setEncManufacturerID(
				productGeneralDetailsResData.output.commonAttribute.encManufacturerID,
			);

			setProductDescription(
				productGeneralDetailsResData.output.commonAttribute.productDescription,
			);
			setIsEndUserScanEnabled(
				productGeneralDetailsResData.output.commonAttribute.isEndUserScanEnabled,
			);
			setCommonProductCode(productGeneralDetailsResData.output.commonAttribute.productCode);
			setProductName(productGeneralDetailsResData.output.commonAttribute.productName);
			setInternalMaterialNumber(
				productGeneralDetailsResData.output.commonAttribute.iMaterialNumber,
			);
			getAllProductAttributesByIdData(
				productGeneralDetailsResData.output.commonAttribute.encProductGroupId,
			);

			setGenericName(productGeneralDetailsResData.output.generalProperties.genericName);

			setComposition(productGeneralDetailsResData.output.generalProperties.composition);

			setStrength(productGeneralDetailsResData.output.generalProperties.strength);

			setGs1CompanyPrefix(
				productGeneralDetailsResData.output.generalProperties.gs1CompanyPrefix,
			);
			setStorageCondition(
				productGeneralDetailsResData.output.generalProperties.storageCondition,
			);

			dispatch(
				setELeafletFileName(
					productGeneralDetailsResData.output.commonAttribute.eLeafletName,
				),
			);

			setImageFileBase64(productGeneralDetailsResData.output.commonAttribute.imageBase64);
			setImageName(productGeneralDetailsResData.output.commonAttribute.imageActualName);

			dispatch(
				setManufacturerGs1Prefix(
					productGeneralDetailsResData.output.generalProperties.gs1CompanyPrefix,
				),
			);
			setManufacturerName(
				productGeneralDetailsResData.output.commonAttribute.manufacturerName,
			);
			setProductGroupName(productGeneralDetailsResData.output.commonAttribute.productGroup);
			setWeblinkType(productGeneralDetailsResData.output.weblinkType);
			setManufacturerLocation(
				productGeneralDetailsResData.output.commonAttribute.manufactureLocations,
			);
			setProductNameSuperScript(
				productGeneralDetailsResData?.output?.commonAttribute?.productNameSuperscript,
			);
			setIsSerialNumberEnabled(
				productGeneralDetailsResData?.output?.commonAttribute?.isSerialNumberEnabled,
			);
		} else {
			setGeneralDetailsLoading(false);
			dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (location?.state) {
			setMode(location?.state?.mode);

			dispatch(setEncProductId(location?.state?.productId));
			getProductGeneralDetailsById({
				productId: location?.state?.productId,
				versionId: location?.state?.versionId,
			});

			setEditCommonAttributes(true);
			setEditGenaralAttributes(true);
			setEditIVedaAttributes(true);
			dispatch(setExpanded(['.0', '.1', '.2']));
		}
	}, [dispatch, getProductGeneralDetailsById, location.state]);
	useEffect(() => {
		setEncVersionId(location.state.versionId);
	}, [location]);
	useEffect(() => {
		const sortedGeneralFieldsData =
			generalFieldsData &&
			generalFieldsData?.generalAttributesList?.map((item: any) => {
				return item?.generalFieldId;
			});

		setSortedGeneralFields(sortedGeneralFieldsData);

		/**
		 * i veda values checking for showing i veda section
		 */

		if (renderCount.current !== 0) {
			if (generalFieldsData && generalFieldsData?.ivedaAttributesList?.length > 0) {
				dispatch(setIVedaPropertiesStatus(true));
			} else {
				dispatch(setIVedaPropertiesStatus(false));
			}
		}
		renderCount.current = 1;

		/**
		 * sorted i veda Fields Data (i veda fields id to display fields)
		 */

		const sortedIVedaPropertiesFieldsData =
			generalFieldsData &&
			generalFieldsData?.ivedaAttributesList?.map((item: any) => {
				return item?.generalFieldId;
			});

		setSortedIVedaPropertiesFields(sortedIVedaPropertiesFieldsData);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [generalFieldsData]);

	/** function for check general fields id active or not */
	const isFieldActive = (param: any) => {
		return sortedGeneralFields?.includes(param);
	};

	/** function for check i-veda fields id active or not */
	const isIVedaPropertiesFieldActive = (param: any) => {
		return sortedIVedaPropertiesFields?.includes(param);
	};

	const resetDownloadStatus = () => {
		setDownloadStatus(false);
	};

	const handleDownload = () => {
		setDownloadStatus(true);
	};

	/**
	 *dowonload e-leaflet API call
	 */
	const { eLeafletDownloadloading } = useDownloadProductVersionEleafletFile(
		downloadStatus,
		encProductId,
		encVersionId,
		resetDownloadStatus,
	);

	const handleSelect = (event: PanelBarSelectEventArguments) => {
		if (event.expandedItems) {
			dispatch(setExpanded(event.expandedItems));
		}
	};

	return {
		productName,
		editCommonAttributes,
		editGeneralAttributes,
		editIVedaAttributes,
		commonProductCode,
		isEndUserScanEnabled,
		productDescription,
		encManufacturerID,

		productGroup,

		errorState,

		iVedaData,
		generalField,
		panelBarStatus,

		mode,
		genericName,

		composition,

		strength,

		gs1CompanyPrefix,

		storageCondition,

		encProductId,

		commonAttributesInfo,
		generalInfo,
		iVedaPropertitesInfo,

		eLeafletDownloadloading,
		eLeafletFileName,
		generalDetailsLoading,

		iVedaPropertiesStatus,

		ivedaErrorState,
		groupChangeLoader,
		eLeafletRef,

		manufacturerName,
		productGroupName,

		imageFileBase64,
		imageName,
		internalMaterialNumber,

		isFieldActive,

		handleSelect,

		handleDownload,
		isIVedaPropertiesFieldActive,
		weblinkType,
		manufacturerLocation,
		productNameSuperScript,
		isSerialNumberEnabled,
	};
};

export default GeneralFunctions;
